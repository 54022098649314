
              @import "@/assets/css/variables.scss";
            

@import '@/assets/css/pages/settings.scss';

.copy_icon {
  font-size: 20px;
}

.buttons_main {
  /deep/ .el-button {
    height: 38px;
  }
}

/deep/ .el-table th .cell {
  padding-top: 20px;
  padding-bottom: 20px;
}

.icon_class {
  width: 18px;
}

.email_main {
  width: 50px;
}
