
              @import "@/assets/css/variables.scss";
            

@import '@/assets/css/pages/settings.scss';

.action_buttons {
  /deep/ .el-button {
    min-width: 182px;
  }
}
