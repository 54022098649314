
              @import "@/assets/css/variables.scss";
            

@import '@/assets/css/pages/settings.scss';

.icon_img {
  width: 15px;
  margin-left: -2px;
  padding-top: 5px;

  @media (max-width: 780px) {
    padding-top: 10px;
  }
}

.custom-option {
  max-width: 320px;
  padding-right: 0px !important;
}

/deep/ .el-select {
  display: block;
  .el-input__inner {
    padding-right: 34px;
    text-overflow: ellipsis;
  }
}

.activity_time {
  text-align: right;
}
