
              @import "@/assets/css/variables.scss";
            

@import '@/assets/css/pages/settings.scss';

.info_ans {
  word-break: keep-all;
  overflow: visible;
}
