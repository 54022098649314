#settings {
  .main_title {
    text-align: left !important;
    font-weight: 600;
    font-size: 20px;
  }

  .btn_submit {
    /deep/ .el-button {
      width: 45%;
    }
  }

  /deep/ .el-input__inner {
    padding-right: 30px !important;
  }
  .remark {
    /deep/ .el-input__inner {
      padding-right: 0px !important;
    }
  }

  .password {
    /deep/ .el-input__inner {
      padding-right: 60px !important;
    }
  }

  /deep/ .el-input__suffix-inner {
    display: flex;
    flex-direction: row;
    margin-right: 0px;
  }

  .content_title {
    border-bottom: 5px solid #41ba92;
  }
  .main {
    margin: 0 auto;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;

    .title {
      font-size: 28px;
      font-family: 'GTWalsheimPro-Bold';
      line-height: 1.42;
      color: $primary-purple;
      margin-bottom: 41px;
    }

    .buttons_main {
      display: flex;
      flex-direction: row;
      gap: 5px;

      .green_button,
      .white_button {
        margin: 0px;
      }
    }

    .info_title {
      display: flex;
      padding: 0px 0 0px !important;
      text-align: left;
    }

    .info {
      padding-top: 30px;
      width: 100%;

      ul {
        display: grid;
        grid-template-columns: 50% 50%;

        li {
          display: inline-block;
          padding-bottom: 30px;

          font-size: 18px;
          min-width: 150px;

          &:first-child {
            font-weight: bold;
            color: $primary-green;

            //padding-top: 41px;
          }

          &:not(:first-child) {
            color: $primary-purple;
            padding-top: 5px;
            text-align: left;
          }
        }
      }
    }
  }
  .action_buttons {
    display: flex;
    justify-content: center;
  }

  .closeImg {
    width: 22px;
    position: absolute;
    top: 20px;
    right: 20px;
    cursor: pointer;
  }

  /deep/ .el-dialog__body {
    padding: 16px 30px 10px;

    @media (max-width: 565px) {
      padding: 20px;
    }
  }

  /deep/ .el-dialog__header {
    padding-top: 30px;
    padding-left: 30px;
    padding-bottom: 20px;

    @media (max-width: 565px) {
      padding-top: 20px;
      padding-left: 20px;
    }
  }
}

@media (max-width: 1024px) {
  #settings .main .info ul {
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    li {
      font-size: 16px;
      padding-top: 15px !important;
      padding-bottom: 15px !important;
    }
  }
}

@media (max-width: 550px) {
  #settings .main {
    padding: 0px;

    .info ul li {
      margin-left: 0px;
      &:first-child {
        padding-top: 10px !important;
        padding-bottom: 0 !important;
      }

      &:last-child {
        padding-top: 0 !important;
        padding-bottom: 10px !important;
      }
    }
  }
}

/** Account Dialog */

#accountDialog {
  /deep/ .el-dialog {
    margin-top: 0 !important;

    .el-dialog__body {
      .el-form-item {
        position: relative;
        margin-bottom: 30px;

        .el-form-item__error {
          height: max-content;
          position: relative;
          margin-bottom: 5px;
        }

        .el-form-item__label {
          position: initial;
          display: flex;

          @include rtl-sass-prop(left, right, 0);
          padding: 0;
          transition: 0.2s;
          font-size: 16px;
          color: $primary-purple;
          @include rtl-sass-value(text-align, left, right);
          line-height: unset;
        }

        .label_class {
          display: flex;
          flex-direction: row;
          gap: 3px;
          align-items: center;

          i {
            color: $primary-green;
          }
        }

        .el-input__inner {
          padding: 10px;
          font-size: 14px;
        }

        .el-input__count-inner {
          margin-top: -18px;
        }

        .el-checkbox-group {
          display: grid;
          grid-template-columns: auto auto;

          @media (max-width: 654px) {
            grid-template-columns: auto auto;
          }

          @media (max-width: 565px) {
            grid-template-columns: auto;
          }
        }
      }
    }
  }
  .status_dynamic {
    display: flex;
    flex-direction: row;
    width: 100%;

    /deep/ .el-form-item {
      ::before {
        display: none;
      }
    }

    /deep/ .el-form-item__content {
      line-height: normal;
      margin-left: 10px;
      margin-top: 2px;
    }

    // /deep/ .el-form-item__label {
    //   display: contents;
    // }
    /deep/ .el-switch__label--right {
      color: $primary-purple !important;
    }
  }

  /deep/ .el-input__inner {
    font-size: 14px;
  }

  /deep/ .el-select .el-input__icon {
    display: block !important;
  }

  /deep/ .el-checkbox__inner {
    border-color: $primary-purple;
  }

  /deep/ .el-checkbox__input.is-checked {
    .el-checkbox__inner {
      background-color: $primary-green;
      border-color: white;

      &::after {
        border-color: white !important;
      }
    }
  }
}

/** Dynamic Dialog */
#dynamicDialog {
  /deep/ .el-dialog {
    margin-top: 0 !important;
  }

  .main_desc {
    display: flex;
    flex-direction: row;
  }

  .dialog_body {
    text-align: center;
    word-break: break-word;
  }

  /deep/ .el-dialog {
    margin-top: 0 !important;

    .el-dialog__body {
      color: $primary-purple !important;
      font-weight: 600;
      display: flex;
      align-items: center;
      justify-content: center;

      span {
        text-align: center;
      }
    }
  }
}

/**SubIbListing */
#accListing {
  width: 100%;
  color: $primary-purple;
  padding: 30px;
  .top_buttons {
    align-items: center;
    display: flex;
    flex-direction: row;

    justify-content: space-between;
    padding-bottom: 15px;

    .account_note {
      display: flex;
      justify-self: center;
    }
  }

  .copy_main {
    display: grid;
    grid-template-columns: 85% 15%;
    gap: 4px;
    align-items: center;
    justify-content: center;

    /deep/ .el-button {
      width: min-content;
      padding: 0;
      border: none;
      color: $primary-green;
      background-color: transparent !important;
    }
  }

  @media (max-width: 500px) {
    .top_buttons {
      flex-direction: column;
      gap: 5px;

      div {
        text-align: right;
      }
    }
    #settings .main {
      padding: 0px;

      .main_info {
        padding: 0px;
      }
    }
  }

  /deep/ .el_table_cell {
    text-align: center;
  }

  .text_left {
    text-align: left;
  }

  .text_overflow {
    display: -webkit-box;
    -webkit-line-clamp: 2; /* Show two lines */
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    cursor: pointer;
  }

  /deep/ .el-button.is-disabled {
    color: white;
    opacity: 0.8;
  }
}

/** SubIbHistoryDialog */
#historyDialog {
  .main_title {
    margin-bottom: 4px;
  }

  .main_desc {
    display: flex;
    flex-direction: row;
    gap: 1%;
    color: $primary-green;

    .refresh_button {
      color: #1d4753;
    }
  }

  /deep/ .el-dialog {
    margin-top: 0 !important;
    @media (max-width: 780px) {
      font-size: 12px !important;
    }

    .el-dialog__header {
      padding-bottom: 10px;
      text-align: left;

      @media (max-width: 780px) {
        font-size: 12px !important;
        padding: 20px;
      }

      .el-dialog__title {
        color: $primary-purple;
        font-weight: 600;
      }
    }

    .el-dialog__body {
      padding: 16px 30px 10px;

      @media (max-width: 780px) {
        padding: 20px;
        padding-top: 16px !important;
      }
    }
  }

  .timeline_item {
    display: flex;
    flex-direction: column;
  }

  #timeline {
    height: 50vh;
    overflow-y: scroll;
    width: 100%;

    @media (max-width: 780px) {
      font-size: 10px !important;
      margin-right: 29px;
    }

    /deep/ .el-divider__text {
      width: max-content;
      padding: 0;
    }

    .el-timeline {
      margin-left: 95px;
      @media (max-width: 780px) {
        margin-left: 0px;
      }
    }

    /deep/ .el-timeline {
      .el-timeline-item__node {
        background: $light-grey;
        margin-top: 8px;
        .el-timeline-item__icon {
          color: $light-grey;
        }
        @media (max-width: 780px) {
          margin-top: 15px;
        }
      }
      .el-timeline-item__tail {
        border-left: 1px dashed $primary-blue;
        height: auto;
        top: 3px;
        bottom: 0px;
        margin-top: 20px;

        @media (max-width: 780px) {
          top: 2px;
          bottom: 0px;
          margin-top: 25px;
        }
      }
      .el-timeline-item__node--large {
        left: 10px;
      }
    }
    .el-timeline-item__wrapper {
      top: -8px;
      text-align: left;
      padding-left: 34px;
    }
    .el-timeline-item__content {
      .timeline-datetime {
        font-size: 12px;
        text-align: end;
      }
    }

    /deep/ .el-timeline-item {
      min-height: 60px;

      .el-timeline-item__wrapper {
        top: 0;
        .el-timeline-item__content {
          color: #909399;
          display: flex;
          flex-direction: row;
          margin-left: -25%;
          gap: 7%;

          @media (max-width: 780px) {
            margin-left: 0;
            flex-direction: column;
          }
        }

        @media (max-width: 780px) {
          padding-left: 15px;
        }
      }
    }

    .activity_title {
      font-size: 12px;
      font-weight: 400;
      color: $primary-purple;

      @media (max-width: 780px) {
        font-size: 12px;
      }
    }

    .activity_desc {
      font-size: 12px;
      font-weight: 400;
      color: $primary-green;
      width: 100%;
      margin-right: 10px;
      max-width: 450px;

      @media (max-width: 780px) {
        max-width: 90%;
      }
    }
  }

  .custom_timestamp {
    margin-top: 8px;
    position: relative !important;
    top: -5px;
    font-size: 13px;
    display: flex;
    color: $primary-green;
    flex-direction: column;
    text-align: left;
    @media (max-width: 780px) {
      flex-direction: row;
      gap: 4%;
      font-size: 12px;
    }
  }

  .custom_divider {
    flex: 1;
    height: 0;
    border-top: 2px dotted $primary-purple; /* Dotted line style */
    background-color: transparent;
  }

  .history_end {
    display: flex;
    align-items: center;

    .end_text {
      margin: 0 5px;
      color: $primary-purple;
    }
  }

  .filter_box {
    color: $primary-purple;
    font-weight: 600;
    margin-top: 20px;
    font-size: 14px;
    display: grid;
    grid-template-columns: max-content auto;
    justify-items: center;
    align-items: center;
    gap: 10px;
    padding-bottom: 20px;
    border-bottom: 1px solid $light-grey;

    /deep/ .el-input__inner {
      font-size: 14px;
    }

    /deep/ .el-select .el-input__icon {
      display: block !important;
    }
  }
}

/**Password Dialog */
#passwordDialog {
  /deep/ .el-dialog {
    margin-top: 0 !important;

    @media (max-width: 665px) {
      width: 90% !important;
    }

    .el-dialog__body {
      .el-form-item {
        position: relative;
        margin-bottom: 0px;

        .el-form-item__error {
          height: max-content;
          position: relative;
          margin-bottom: 5px;
        }

        .el-form-item__label {
          position: initial;
          display: flex;
          margin-bottom: 5px;

          @include rtl-sass-prop(left, right, 0);
          padding: 0;
          transition: 0.2s;
          font-size: 16px;
          color: $primary-purple;
          @include rtl-sass-value(text-align, left, right);
          line-height: unset;
        }

        .label_class {
          display: flex;
          flex-direction: row;
          gap: 3px;
          align-items: center;

          i {
            color: $primary-green;
          }
        }

        .el-input__inner {
          padding: 10;
          font-size: 14px;
        }
      }
    }
  }
  .status_dynamic {
    display: flex;
    flex-direction: row;
    width: 100%;

    /deep/ .el-form-item {
      ::before {
        display: none;
      }
    }

    /deep/ .el-form-item__content {
      line-height: normal;
      margin-left: 10px;
      margin-top: 2px;
    }

    // /deep/ .el-form-item__label {
    //   display: contents;
    // }
    /deep/ .el-switch__label--right {
      color: $primary-purple !important;
    }
  }

  /deep/ .el-input__inner {
    font-size: 14px;
  }

  /deep/ .el-select .el-input__icon {
    display: block !important;
  }
}
