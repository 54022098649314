
              @import "@/assets/css/variables.scss";
            

@import '@/assets/css/pages/settings.scss';

.tooltip_body {
  max-width: 280px;
  white-space: pre-wrap;
}
